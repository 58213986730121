<template src="./checkUserCPF.html" />

<script>
import { useStore } from "@/store/store.js"
import mixins from "@/mixins/mixins.js"

export default {
  mixins: [mixins],

  name: "check-user-cpf",
  
  data() {
    return {
      message: "",
      cpfFound: false,
      pinIsValid: false,
      cpf: "",
      pin: "",
    }
  },

  setup () {
    const store = useStore()

    return {
      getUserDataByCpf: (cpf) => store.dispatch("getUserDataByCpf", cpf),
    }
  },

  methods: {
    async checkCPF() {
      if (this.cpf) {
        this.loader(true)
        const cpf = this.cpf.replace(/[^\d]+/g, "")
        const response = await this.getUserDataByCpf(cpf)

        this.cpfFound = !!response

        if (response) {
          this.message = ""
          this.cpfFound = true
          this.user = response
          this.firstAccess = response.firstAccess

          if (this.user && this.firstAccess) {
            this.$emit("showFormUserRegister", this.user)
          } else {
            this.$vs.notify({
              title: "Atenção!",
              text: `O CPF digitado já consta na nossa base de dados. Você será
                encaminhado para a tela de login.`,
              time: 3000,
              icon: "error",
              color: "warning",
              position: "top-right",
            })

            setTimeout(() => {
              this.loader()
            }, 250)

            setTimeout(() => {
              this.$router.push("/login")
            }, 2500)
          }
        } else {
          this.message = `CPF não encontrado. Para contratar 
            <a href="https://filoo.com.br/para-familia">clique aqui</a>.`

          this.user = null
          this.loader()
        }
      }
    },

    checkPIN() {
      if (this.testNullUndefinedEmpty(this.pin)) return
      this.loader(true)
      this.axios
        .get(this.$store.state.filooServer + "acess-by-token?pin=" + this.pin)
        .then(({data, status}) => {
          if (status === 200) {
            this.message = ""
            this.pinIsValid = data
            if (this.pinIsValid) {
              this.$router.push({
                name: "register-with-token",
                params: { token: this.pin },
              })
            }
          } else {
            this.message = "Token inválido"
            this.pinIsValid = false
          }
        })
        .catch((error) => {
          this.message =
            "PIN não encontrado. Para contratar a Filóo, clique em Faça Parte."
          if (
            error.data.message ===
            "O questionário de mapeamento deve ser preenchido antes de você terminar seu cadastro."
          ) {
            this.$bvModal.show("modal-pending-payment")
            this.message = ""
          }
          this.pinIsValid = false
        })
      this.loader()
    },

    checkInfo() {
      this.$gtm.trackEvent({
        // click_btn_pc_primeiroacesso_confirmar
        event: "first_access_require_btn_click",
        category: "Primeiro Acesso",
        action: "first_access_require_btn_click",
        label: "Na página “First-Access” (PORTAL CLIENTE) - Clicar no botão “Solicitar”",
      })
  
      this.checkCPF()
      this.checkPIN()
    },
  },

  watch: {
    cpf () {
      this.message = ""
    },
  },
}
</script>

<style lang="scss" src="./checkUserCPF.scss" scoped />
