<template src="./firstAccess.html" />

<script>
import checkUserCpf from "./checkUserCPF/checkUserCPF";
import finalizeRegistration from "./finalizeRegistration/finalizeRegistration";
import { useStore } from "@/store/store.js"

export default {
  components: {
    checkUserCpf,
    finalizeRegistration,
  },
  data() {
    return {
      userFirstAccessData: null,
    }
  },
  setup() {
    const store = useStore()

    return {
      getUserDataByCpf: (cpf) => store.dispatch("getUserDataByCpf", cpf),
    }
  },
  async mounted() {
    const cpf = this.$route.params.cpf

    if (cpf) {
      this.loader(true)
      const userData = await this.getUserDataByCpf(cpf)
      if (userData) {
        this.$router.push({ name: "finalize-registration", params: { userData } })
      }
      this.loader()
    }
  },
  methods: {
    goToForm(userData) {
      this.loader(true)
      const term = "dasa"

      const checkName = userData.customerPlan.name.toLowerCase().indexOf(term) === -1
      const checkCode = userData.customerPlan.code.toLowerCase().indexOf(term) === -1
      const checkMultPlan = userData.customerPlan.multPlanCode 
        ? userData.customerPlan.multPlanCode.toLowerCase().indexOf(term) === -1
        : false

      this.loader()
      if (checkName || checkCode || checkMultPlan) {
        window.location.href = `${this.$store.state.filooPortalClientUrl}#/first-access/${userData.cpf}`
      } else {
        this.$router.push({ name: "finalize-registration", params: { userData } })
      }
    },
  },
};
</script>

<style lang="scss" src="./firstAccess.scss" />
